/** @jsx jsx */
import { Box, Flex, jsx } from "theme-ui"
import BlogLink from "./BlogLink"
import { ArrowBack } from "@emotion-icons/boxicons-regular/ArrowBack"

const BackLink = (): JSX.Element => {
  return (
    <Box>
      <BlogLink to="/blog">
        <Flex color="accent" sx={{ alignItems: "center" }}>
          <span sx={{ display: "inline-block", mr: 2 }}>
            <ArrowBack size="1.4rem" />
          </span>
          <Box>back to all</Box>
        </Flex>
      </BlogLink>
    </Box>
  )
}

export default BackLink
