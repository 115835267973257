/** @jsx jsx */
import React from "react"
import { Box, Text, Heading, Divider, jsx } from "theme-ui"
import { CategoryInterface } from "../../types/category"
import { useStaticQuery, graphql, Link } from "gatsby"
import { getCategoryLink, getCategories } from "../../utils/category"
import { getPosts } from "../../utils/post"
import BlogDivider from "./BlogDivider"

interface CategoryProps {
  category: CategoryInterface
  count: number
}

const Category = (props: CategoryProps): JSX.Element => {
  const { category, count } = props
  return (
    <Box py={1}>
      <Text>
        {" "}
        -{" "}
        <Link
          sx={{
            color: "text",
            textDecoration: "none",
            fontSize:[1,1],
            "&:hover": { textDecoration: "underline" },
          }}
          to={getCategoryLink(category)}
        >
          {category.name} ({count})
        </Link>
      </Text>
    </Box>
  )
}

const Categories = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query Categories {
      allPrismicPost(sort: { fields: data___date, order: DESC }) {
        edges {
          node {
            url
            data {
              title {
                text
              }
              categories {
                category {
                  document {
                    ... on PrismicCategory {
                      data {
                        name
                      }
                    }
                  }
                }
              }
              content {
                raw
              }
              date(formatString: "MMM D, YYYY")
              main_image {
                alt
                url
              }
              description {
                text
              }
            }
          }
        }
      }
      allPrismicCategory {
        nodes {
          data {
            name
          }
        }
      }
    }
  `)

  const categories = getCategories(data)
  const posts = getPosts(data)

  return (
    <Box py={3}>
      <Heading mb={2} sx={{ fontSize: [1,4] }}>
        Categories
      </Heading>
      <BlogDivider />
      <Box>
        {categories.map((category, index) => {
          const count = posts.filter(post =>
            post.categories.some(c => c.name === category.name)
          ).length
          return <Category key={index} category={category} count={count} />
        })}
      </Box>
    </Box>
  )
}

export default Categories
