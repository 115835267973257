/** @jsx jsx */
import React from "react"
import { Box, Text, Heading, Divider, jsx } from "theme-ui"
import { PostInterface } from "../../types/post"
import CategorySubLink from "./CategorySubLink"
import BlogLink from "./BlogLink"
import { getPostLink, getPosts } from "../../utils/post"
import { useStaticQuery, graphql } from "gatsby"
import BlogDivider from "./BlogDivider"

interface RecentPostProps {
  post: PostInterface
}

const RecentPost = (props: RecentPostProps): JSX.Element => {
  const { post } = props

  return (
    <Box sx={{ maxWidth: "250px" }} py={2}>
      <Text sx={{ fontSize: [0, 1] }} variant="secondary">
        {post.categories.map((category, index) => (
          <CategorySubLink key={index} category={category} />
        ))}
      </Text>
      <BlogLink to={getPostLink(post)}>
        <Heading sx={{ fontWeight: "500", fontSize: [1, 2] }}>
          {post.title}
        </Heading>
      </BlogLink>
      <Text sx={{ fontSize: [0, 1] }} mt={1} variant="secondary">
        {post.date} - 4 min read
      </Text>
    </Box>
  )
}

const RecentPosts = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query RecentPostsQuery {
      allPrismicPost(sort: { fields: data___date, order: DESC }, limit: 5) {
        edges {
          node {
            url
            data {
              title {
                text
              }
              categories {
                category {
                  document {
                    ... on PrismicCategory {
                      data {
                        name
                      }
                    }
                  }
                }
              }
              content {
                raw
              }
              date(formatString: "MMM D, YYYY")
              main_image {
                alt
                url
              }
              description {
                text
              }
            }
          }
        }
      }
    }
  `)

  const posts = getPosts(data)

  return (
    <Box py={3}>
      <Heading mb={2} sx={{ fontSize: [1,4] }}>
        Recent Posts
      </Heading>
      <BlogDivider />
      <Box>
        {posts.map((post, index) => (
          <RecentPost key={index} post={post} />
        ))}
      </Box>
    </Box>
  )
}

export default RecentPosts
