/** @jsx jsx */
import React from "react"
import { Box, Heading, Text, Grid, Flex, jsx } from "theme-ui"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import { RichText } from "prismic-reactjs"
import RecentPosts from "../components/Blog/RecentPosts"
import Categories from "../components/Blog/Categories"
import SharePost from "../components/Blog/SharePost"
import PageHeader from "../components/PageHeader"
import CategorySubLink from "../components/Blog/CategorySubLink"
import PostNavigationItem from "../components/Blog/PostNavigationItem"
import BackLink from "../components/Blog/BackLink"
import { withPreview } from "gatsby-source-prismic"
import BlogDivider from "../components/Blog/BlogDivider"

const Post = ({ pageContext: { post, prevPost, nextPost } }) => {
  return (
    <Box>
      <SEO title={`${post.title} | Blog`} />
      <Layout>
        <PageHeader>
          <Text sx={{ fontSize:[2,5], fontWeight: "bold", textAlign: "center" }}>
            {post.title} | Blog
          </Text>
        </PageHeader>
        <Box mt={3} px={3} sx={{ maxWidth: "1100px", mx: "auto" }}>
          <BackLink />
          <Grid columns={[1, 1, 1, "1fr auto"]}>
            <Box>
              <Box sx={{ textAlign: "center" }}>
                <img
                  sx={{
                    maxWidth: "90%",
                    maxHeight: "500px",
                    my: 4,
                  }}
                  src={post.image.url + "&w=800"}
                  alt={post.image.alt}
                />
              </Box>
              <Heading sx={{ fontSize: [2,5] }}>{post.title}</Heading>
              <Text sx={{fontSize:[0,1]}} variant="secondary">{post.date} - 4 min read</Text>
              <Flex mt={1} sx={{ justifyContent: "space-between" }}>
                <Box>
                  {post.categories.map(category => (
                    <CategorySubLink category={category} />
                  ))}
                </Box>
                <SharePost post={post} />
              </Flex>
              <Box
                sx={{
                   fontSize:[0,1],
                  "& img": {
                    maxWidth: "100%",
                  },
                  "& li": {
                    my: 1,
                   
                  },
                }}
              >
                {RichText.render(post.content)}
              </Box>
            </Box>
            <Box>
              <RecentPosts />
              <Categories />
            </Box>
          </Grid>
          <Box mt={[2,5]}>
            <Text mb={3} sx={{ fontSize: [2,5] }}>
              More Articles
            </Text>
            <BlogDivider />
            <Flex
              mt={4}
              sx={{ justifyContent: "flex-start", flexWrap: "wrap" }}
            >
              <PostNavigationItem post={prevPost} />
             
            </Flex>
          </Box>
        </Box>
      </Layout>
    </Box>
  )
}

export default withPreview(Post)
