/** @jsx jsx */
import React from "react"
import { Box, Text, Heading, jsx } from "theme-ui"
import { PostInterface } from "../../types/post"
import BlogLink from "./BlogLink"
import { getPostLink } from "../../utils/post"

interface PostNavigationItemProps {
  post: PostInterface
}

const PostNavigationItem = (props: PostNavigationItemProps): JSX.Element => {
  const { post } = props

  return (
    <Box
      sx={{
        maxWidth: "450px",
        "&:hover .postImage": { transform: "scale(1.2)" },
      }}
      mb={[-3,4]}
    >
      <Box sx={{ textAlign: "center" }}>
        <BlogLink to={getPostLink(post)}>
          <Box
            sx={{
              width: "100%",
              height: "200px",
              overflow: "hidden",
            }}
          >
            <img
              className={"postImage"}
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                transition: "transform 0.3s",
              }}
              src={post.image.url + "&w=450"}
              alt={post.image.alt}
            />
          </Box>
        </BlogLink>
      </Box>
      <Box>
        <BlogLink to={getPostLink(post)}>
          <Heading my={2} sx={{ fontSize: [1,4] }}>
            {post.title}
          </Heading>
        </BlogLink>
        <Text sx={{fontSize:[0,1]}} mt={1} variant="secondary">
          {post.date} - 4 min read
        </Text>
      </Box>
    </Box>
  )
}

export default PostNavigationItem
